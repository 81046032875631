/*
 * Author: Anderson Vicente
 * 
 * Project Name: Portfolio
 * Version: 1.0
 * Date: 11-04-2019
 * URL: 
 */
 @import '_variables';
 @import '_mixins';
 @import '_fonts';
 
 body {
    background-color: $body-bg-color;
    color: $body-color;
    font-family: $font-open-sans;
    font-size: 1em;
 }
 
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
    font-family: $font-montserrat;
    margin: 0;
    padding: 0;
 }
  
 #header {
    text-align: center;
    h1 {
        font-size: 4em;
        font-weight: bold;
        color: $cinza-escuro;
    }
    h4 {
        font-size: 1em;
    }
}

.section-title{
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    color: $cinza-claro;
 }
 .section-title:before {
    border-top: 1px solid $cinza-claro-bt;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
 }
 .section-title span {
    background: $portfolio-bg;
    padding: 0 0.5em;
 }
 .section-title.bg-white span {
    background: #FFFFFF;
 }
 #skills{
    color: $cinza-claro;
 }

/* portfolio */
 #portfolio {
    padding: 0;
    @include box-shadow-inset( 0px 0px 12px 0px rgba(0, 0, 0, 0.1) );
    background: $portfolio-bg;
    color: $cinza-claro;
 }
   #portfolio .card {
    @include box-shadow(0px 2px 12px rgba(0, 0, 0, 0.4));
    @include transition(0.3s);
 }
 #portfolio .card:hover {
    @include box-shadow(0px 4px 14px rgba(0, 0, 0, 0.7));
 }
 #portfolio .card-lightbox,
 #portfolio .card-lightbox:hover{
     color: $body-bg-color;
     text-decoration: none;
 }	
 #portfolio .card-title{
     font-weight: bold;
     font-size: 1em;
 }
 #portfolio .card-text{
     font-size: 0.9em;
 }
 .card-content{
     position: relative;
 }
 
 .card-content .content-overlay {
     background: $overlay;
     position: absolute;
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     bottom: 0;
     right: 0;
     opacity: 0;
     @include transition( all 0.3s ease-in-out 0s );
 }
   
 .card-content:hover .content-overlay{
     opacity: 1;
 }
 .card-img-overlay>h4, .card-img-overlay>p{
     visibility:hidden;
 }
 .card-img-overlay:hover h4, .card-img-overlay:hover p{
     visibility: visible;
     @include transition( all 0.3s ease-in-out 0s );
 }
 
 .social a{
     margin: 0 0.5em;
     color: $cinza-claro-bt;
 }
 
 
 .divide{
     width: 40em;
     border-top: 1px solid $cinza-claro-2;
     border-bottom: 1px solid $body-bg-color;
 }

 /* SKILLS PROGRESS */
 
.progress-wrap .title {
    font-size: 16px;
    margin-bottom: 10px;
}
.progress {
    height: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: $progress-bg;
    overflow: visible;
    margin-bottom: 20px;
    border-radius: 4px;
    box-sizing: border-box; 
    @include box-shadow-inset( 0 1px 2px rgba(0,0,0,.1) );
}

.progress-bar {
    background: $progress-color;
    font-size: 12px;
    line-height: 1.2;
    text-align: right;
    position: relative;
    overflow: visible;
    @include border-radius( 8px);
    @include box-shadow-inset( 0 -1px 0 rgba(0,0,0,.15));
    &:after{
		position: absolute;
		top: -2px;
		right: 0;
		width: 10px;
		height: 10px;
		content: '';
		background: $progress-color;
		@include border-radius(50%);
    }
    span{
        font-weight: bold;
        color: $progress-color;
		position: absolute;
		bottom: -18px;
		right: 0;
	}
}
$cv-icon-size: 50px;
#resume{
    color: #4e4e4e;
}
.cv_edu_title i {
    background: #4e4e4e;
    font-size: 1em;
    border-radius: 50%;
    width: $cv-icon-size;
    height: $cv-icon-size;
    line-height: $cv-icon-size;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    margin-right: 6px;
}
#contact{
    padding: 0;
    
    background: $cinza-claro;
    color: $portfolio-bg;
}