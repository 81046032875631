/*
 * Author: Anderson Vicente
 * 
 * Project Name: Portfolio
 * Version: 1.0
 * Date: 11-04-2019
 * URL: 
 */
@import url(//fonts.googleapis.com/css?family=Montserrat);
@import url(//fonts.googleapis.com/css?family=Open+Sans);
body {
  background-color: #ffffff;
  color: #909090;
  font-family: "Open Sans", sans-serif;
  font-size: 1em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0; }

#header {
  text-align: center; }
  #header h1 {
    font-size: 4em;
    font-weight: bold;
    color: #333333; }
  #header h4 {
    font-size: 1em; }

.section-title {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  color: #4e4e4e; }

.section-title:before {
  border-top: 1px solid #909090;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1; }

.section-title span {
  background: #f7f7f7;
  padding: 0 0.5em; }

.section-title.bg-white span {
  background: #FFFFFF; }

#skills {
  color: #4e4e4e; }

/* portfolio */
#portfolio {
  padding: 0;
  -webkit-box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background: #f7f7f7;
  color: #4e4e4e; }

#portfolio .card {
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
  -ms-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

#portfolio .card:hover {
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7);
  -ms-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7);
  -o-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.7); }

#portfolio .card-lightbox,
#portfolio .card-lightbox:hover {
  color: #ffffff;
  text-decoration: none; }

#portfolio .card-title {
  font-weight: bold;
  font-size: 1em; }

#portfolio .card-text {
  font-size: 0.9em; }

.card-content {
  position: relative; }

.card-content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.card-content:hover .content-overlay {
  opacity: 1; }

.card-img-overlay > h4, .card-img-overlay > p {
  visibility: hidden; }

.card-img-overlay:hover h4, .card-img-overlay:hover p {
  visibility: visible;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }

.social a {
  margin: 0 0.5em;
  color: #909090; }

.divide {
  width: 40em;
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #ffffff; }

/* SKILLS PROGRESS */
.progress-wrap .title {
  font-size: 16px;
  margin-bottom: 10px; }

.progress {
  height: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #f7f7f7;
  overflow: visible;
  margin-bottom: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

.progress-bar {
  background: #707070;
  font-size: 12px;
  line-height: 1.2;
  text-align: right;
  position: relative;
  overflow: visible;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15); }
  .progress-bar:after {
    position: absolute;
    top: -2px;
    right: 0;
    width: 10px;
    height: 10px;
    content: '';
    background: #707070;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
  .progress-bar span {
    font-weight: bold;
    color: #707070;
    position: absolute;
    bottom: -18px;
    right: 0; }

#resume {
  color: #4e4e4e; }

.cv_edu_title i {
  background: #4e4e4e;
  font-size: 1em;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  margin-right: 6px; }

#contact {
  padding: 0;
  background: #4e4e4e;
  color: #f7f7f7; }
