
@mixin box-shadow ($value) {
    -webkit-box-shadow: $value;
       -moz-box-shadow: $value;
        -ms-box-shadow: $value;
         -o-box-shadow: $value;
            box-shadow: $value;
}
@mixin box-shadow-inset ($value) {
    -webkit-box-shadow: inset $value;
       -moz-box-shadow: inset $value;
        -ms-box-shadow: inset $value;
         -o-box-shadow: inset $value;
            box-shadow: inset $value;
}

@mixin transition ($value) {
    -webkit-transition: $value;
       -moz-transition: $value;
        -ms-transition:	$value;
         -o-transition: $value;
            transition: $value;
}
@mixin transition-ease($value) {
    -webkit-transition: all $value ease;
    -moz-transition:    all $value ease;
    -ms-transition: 	all $value ease;
    -o-transition:      all $value ease;
    transition:         all $value ease;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}