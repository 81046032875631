$body-bg-color: #ffffff;
$body-color: #909090;

$cinza-escuro: #333333;
$cinza-claro: #4e4e4e;
$cinza-claro-2: #8c8b8b;
$cinza-claro-bt: #909090;

$portfolio-bg: #f7f7f7;

$overlay: rgba(0,0,0,0.7);

$font-open-sans: 'Open Sans', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;

$font-primary: 'Montserrat', sans-serif;

$progress-bg: #f7f7f7;
$progress-color: #707070;

